import automation from '../img/change-history/process-changed.png';
import person from '../img/change-history/person-changed.png';
import unknown from '../img/change-history/unknown-changed.png';
import React, { useContext, useEffect, useState } from "react";
import { Grid } from '@mui/material'
import { timeAgo, dateIsValid } from '../utils/DateTime.js'
import Tooltip from '../form/Tooltip.js'
import RoleContext from '../auth/RoleContext'
import { ROLES } from '../auth/role'
import {Skeleton} from '@mui/material';

const formatSource = (source) => {
  const src = (source || '').replace(/(http|https):\/\//, '')
  const mfiFormatted = `MFI (${src})`
  if ((/localhost/).test(src)) return mfiFormatted
  if ((/portal(\.dev|\.test)?\.tis\.coke.com/).test(src)) return mfiFormatted
  return source
}

export default function HistoryDetails(props) {
  const roleDetails = useContext(RoleContext)

  const {
    changes = [],
    loading
  } = props
  const [timeAgoValue, setTimeAgoValue] = useState()
  const [latestChange, setLatestChange] = useState({})

  const isAdmin = roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN])

  useEffect(() => {
    const _latestChange = changes.find(f => {
      return f.propertyName === props.id
    }) || {}

    const timeAgoInterval = setInterval(() => {
      setTimeAgoValue(timeAgo(latestChange.utcTimestamp))
    }, 60000)

    setTimeAgoValue(timeAgo(_latestChange.utcTimestamp))
    setLatestChange(_latestChange)

    return () => {
      if (timeAgoInterval) {
        clearInterval(timeAgoInterval)
      }
    }
  }, [props])

  const isAutomation = latestChange.changedBy ? latestChange.changedBy.indexOf(' ') === -1 : false
  const icon = latestChange.changedBy ? <img
    className="change-icon"
    alt="Changed By"
    src={isAutomation ? automation : person}
  /> : <img alt="Changed By" src={unknown} />

  const validTimestamp = dateIsValid(latestChange.utcTimestamp)

  return (
    <span className="history-span">
      {loading && <Skeleton width="160px"/>}
      {!loading && <>
      {validTimestamp && <Tooltip location={props.tooltipLocation} content={
        <div style={{ paddingLeft: '10px' }}>
          <span>
            <span className='secondary'>last modified</span> {timeAgoValue} <br />
            <span className='secondary'>at</span> {new Date(latestChange.utcTimestamp).toLocaleString()} <br />
            <span className='secondary'>by</span> {latestChange.changedBy} <br />
            <span className='secondary'>through</span> {formatSource(latestChange.source)} <br />
            {latestChange.hasOwnProperty('oldValue') && isAdmin && <span>
              <span className='secondary'>from</span> "{props.valueMapper ? props.valueMapper(latestChange.oldValue) : latestChange.oldValue}"
            </span>}
          </span>
          {!validTimestamp && <span className='secondary'>No change history</span>}
        </div>
      }>
        <span className={`history-label ${isAutomation ? 'automation' : ''}`}>{timeAgoValue}</span>
        &nbsp;
        {icon}
      </Tooltip>}
      {!validTimestamp && <span className="secondary history-label">
        no change history
      </span>}
      </>}
    </span>
  )

}
