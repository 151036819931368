import { useMemo, useState, useContext, useEffect } from 'react'
import { Skeleton, ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, Autocomplete, Grid, Button, InputLabel, Select, TextField, Typography } from '@mui/material'
import { getRequestConfig, getToken } from '../auth/auth'
import { ApiGateway } from '../config/config'
import RoleContext from '../auth/RoleContext'
import UserContext from '../auth/UserContext'
import { ROLES } from '../auth/role'
import axios from 'axios'

export default function QSEAutoComplete(props = { initializeDefaultUser: false, defaultUserId: undefined, onChange: undefined, label: undefined, placeholder: undefined }) {
  const userDetails = useContext(UserContext)

  const [userList, setUserList] = useState([])
  const [userAcList, setUserAcList] = useState([])
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  // const loading = false
  const { onChange: onChangeFromProps } = props

  const loadUsers = async () => {
    setLoading(true)
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.user}/search`
    const roleId = ROLES.QSE_MGR.id
    const assessorOptions = await axios.post(basePath, { roleId }, requestConfig )

    if(assessorOptions && assessorOptions.status >= 200 && assessorOptions.status < 300) {
      setUserList(assessorOptions.data)
      setUserAcList(assessorOptions.data)
    } else {
      setUserList([{label: 'unable to load data', value: '-1'}])
    }
    setLoading(false)
  }

  useEffect(() => {
    loadUsers()
  }, [])

  useEffect(() => {
    if(userList?.length) {
      if(props.defaultUserId) {
        const currentUser = userList.find(assessor => assessor.koId === props.defaultUserId)
        if(currentUser) {
          setUserId(currentUser.koId)
        }

        if(onChangeFromProps && props.initializeDefaultUser) {
          onChangeFromProps(props.defaultUserId)
        }
      } 
    }
  }, [userList, props.defaultUserId])

  const user = useMemo(() =>
    userList.find(u => `${u.koId}` === `${userId}`) || null,
    [userList, userId]
  )

  return loading ? <Skeleton sx={{height: '37.8px'}}/> : <Autocomplete
    id="user"
    getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} (${option?.koId})` || {}}
    filterOptions={(x) => x}
    options={userAcList}
    autoComplete
    size='small'
    filterSelectedOptions
    value={user}
    noOptionsText="No results"
    onChange={(event, newValue) => {
      setUserId(newValue?.koId)
      onChangeFromProps(newValue?.koId)
    }}
    onInputChange={(event, newInputValue) => {
      if (!newInputValue) {
        setUserAcList(userList)
      } else {
        const newUserList = userList.filter(user => {
          const lowerInput = (newInputValue || "").toLowerCase();
          const firstName = (user.firstName || "").toLowerCase();
          const lastName = (user.lastName || "").toLowerCase();
          const koId = (user.koId || "").toLowerCase();
          return firstName.includes(lowerInput) || lastName.includes(lowerInput) || koId.includes(lowerInput)
        })
        setUserAcList(newUserList)
      }
    }}
    renderInput={(params) => (
      <TextField {...params} value={params.value || ''} label={props.label === false ? undefined : (props.label || 'QSE Manager')} fullWidth placeholder={props.placeholder === false ? undefined : (props.placeholder || 'QSE Manager')} />
    )}
    renderOption={(props, option) => {
      return (
        <li {...props} key={props.key}>
          <Grid container alignItems="center">
            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
              <Typography variant="body2" color="text.secondary">
                {option?.firstName} {option?.lastName} ({option?.koId})
              </Typography>
            </Grid>
          </Grid>
        </li>
      );
    }}
  />
}