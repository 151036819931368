import Layout from './layout/Layout'
import './css/adminlte.css'
import './css/App.css'
import { Fragment, useEffect, useState } from 'react'
import UserContext from '../app/auth/UserContext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getUserProfile } from './auth/profile'
import SessionTimeout from './auth/SessionTimeout'
import { Stage } from './config/config'
import Clarity from '@microsoft/clarity'

function App(props = {}) {
  const [userToken, setUserToken] = useState()
  const [user, setUser] = useState()
  const [userProfile, setUserProfile] = useState()

  const login = async () => {
    const {token, profile} = await getUserProfile()
    setUserProfile(profile)
    setUserToken(token)
    const claims = token?.idTokenClaims
    setUser({
      koId: claims?.koId,
      name: claims?.name,
      email: claims?.mail,
      department: claims?.department
    })
  }

  const clarityProjectId = (Stage === 'prod') ? undefined : 'q0itt0erab'
  if (clarityProjectId && !window.location.host.includes('localhost')) {
    Clarity.init(clarityProjectId)
  }

  const setClarityUser = () => {
    if (window.clarity) {
      Clarity.identify(userProfile.koId)
    }
  }

  useEffect(() => {
    if (userProfile) {
      setClarityUser()
    }

  }, [userProfile])

  useEffect(() => {
    if (!userToken) {
      login()
    }
  }, [userToken])
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Fragment>
      {userToken && 
      <UserContext.Provider value={{ ...user, userProfile}}>
        <Layout {...props} />
        <SessionTimeout token={userToken} profile={userProfile} />
      </UserContext.Provider>
      }
    </Fragment>
    </LocalizationProvider>
  )
}

export default App
