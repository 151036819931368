import { default as React, useState } from 'react'
import { Factory, FormatColorResetOutlined } from '@mui/icons-material'
import ConfirmDialog from '../form/ConfirmDialog'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import Page from '../layout/Page'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Autocomplete, FormHelperText, Grid, Paper, TextField, Typography } from '@mui/material'
import { DateField, DatePicker } from '@mui/x-date-pickers'
import { validateFieldChange } from '../form/Form'
import { useEffect } from 'react'
import { getRequestConfig } from '../auth/auth'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Stack } from '@mui/system'

export default function ProductionVolumePage(props = {}) {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  let navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isDirty, setIsDirty] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [mfrProfileId ] = useState(params.mfrProfileId)
  const _isNew = ['create', ''].includes(params.volumeId)
  const [isNew ] = useState(_isNew)
  const [volumeId] = useState(_isNew ? undefined : params.volumeId)
  const [manufacturerProfile, setManufacturerProfile] = useState()
  const [submissionError, setSubmissionError] = useState()
  const [productionCodeOptions, setProductionCodeOptions] = useState([])

  const previousMonthDate = new Date()
  previousMonthDate.setDate(0)
  previousMonthDate.setDate(1)

  const previousYearDate = new Date()
  previousYearDate.setFullYear(previousYearDate.getFullYear()-1)

  const [volumeRecord, setVolumeRecord] = useState({
    volumeDate: previousMonthDate.toDateString(),
    volumeAmount: undefined
  })

  const setDirty = (dirty=true) => {
    setIsDirty(dirty)
  }

  const validateForm = (_record = false) => {
    let valid = false

    if ( !_record ) _record = volumeRecord
    if ((_record.volumeAmount && !isNaN(_record.volumeAmount) && _record.volumeAmount > 0)
        && ![undefined, ''].includes(_record.productionCode)) {
      valid = true
    }
    setIsValid(valid)
    setDirty(valid)
    return valid
  }

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const confirmCancel = () => {
    if (isDirty) { showDialog() }
    else handleClose()
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigate(`/manufacturer/production-volumes`)
    } else {
      navigate(-1)
    }
  }

  const breadcrumb = <div><span><Factory />&nbsp;Manufacturer&nbsp;Production&nbsp;Volume</span></div>

  const onLoad = async () => {
    startLoading()
    const requestConfig = await getRequestConfig()

    const requests = [
      axios.post(`${ApiGateway.manufacturerProfile}/search`, { id: mfrProfileId }, requestConfig)
    ]
    if (! _isNew ) {
      requests.push(axios.get(`${ApiGateway.manufacturer}/${mfrProfileId}/production-volume/${volumeId}`, requestConfig))
    }
    const responses = await Promise.all(requests)
    // const response = await axios.post(basePath, { id: mfrProfileId }, requestConfig)
    if (responses.length) {
      const mfrProfile = responses[0].data?.results[0]
      setManufacturerProfile(mfrProfile)
      const pc = mfrProfile.Manufacturer.productionCode
      let pcOpts
      if (pc && pc.includes(',')) {
        pcOpts = pc.split(',').map((c) => c.trim())
      } else {
        pcOpts = [pc.trim()]
      }
      setProductionCodeOptions(pcOpts)

      if (! _isNew) {
        setVolumeRecord(responses[1].data[0])
      } else {
        if (pcOpts.length === 1) {
          const vr = { ...volumeRecord }
          vr['productionCode'] = pcOpts[0]
          setVolumeRecord(vr)
        }
      }
    }

    stopLoading()
  }
  const onSave = async () => {

    if (isValid) {
      startLoading()
      setSubmissionError()
      const basePath = `${ApiGateway.manufacturer}/${mfrProfileId}/production-volume/${isNew ? '' : volumeId}`
      const requestConfig = await getRequestConfig()
      try {
        const response = await axios.post(basePath, volumeRecord, requestConfig)
        const savedProductionVolume = response.data
        if (savedProductionVolume) {
          navigate(`/manufacturer/production-volume/${mfrProfileId}/${savedProductionVolume.id}`, { replace: true })
          setVolumeRecord(savedProductionVolume)
        }
        setDirty(false)

      } catch (error) {
        setSubmissionError(error.response?.data)
        console.error(error)
      }
      stopLoading()
    }
  }

  useEffect(() => {
    onLoad()
  }, [mfrProfileId])

  return (

    <Page {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
      <FormViewCommandBar onSave={onSave} onCancel={confirmCancel} enableSave={isDirty} showClose={!isDirty} showCancel={isDirty} onClose={confirmCancel} />
    }>
      <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleClose} yesLabel="Discard" noLabel="Stay on Page" />
      {manufacturerProfile &&
      <Paper variant='elevation' sx={{ padding: '5px', marginBottom: '5px'}}>
        <Grid container>
          <Grid item sm={12} md={12} paddingBottom={3}>
            <Typography variant='h6'>Reporting for {manufacturerProfile.Manufacturer.manufacturerId} - {manufacturerProfile.Manufacturer.manufacturerName}</Typography>
          </Grid>
          {submissionError && 
          <Grid item sm={12} md={12} paddingBottom={3}>
            <Typography className='tis-alert-error' sx={{ color: '#FFF', padding: '5px'}} >Error occurred attempting to save: {submissionError}</Typography>
          </Grid>
          }
          <Grid item sm={12} md={12} padding={1}>
            <Stack direction={'row'} spacing={2} >
              <Typography fontWeight='bold' width={225}>Production Volume Date:</Typography>
              <DatePicker
                value={dayjs(volumeRecord.volumeDate)}
                timezone='UTC'
                // views={['month','year']}
                // openTo='month'
                minDate={_isNew ? dayjs(previousYearDate) : undefined}
                maxDate={_isNew ? dayjs() : undefined }
                disableFuture
                slotProps={{
                  textField: {
                    size: 'small'
                  }
                }}
                onChange={(value) => {
                  console.log(value.toISOString())
                  const d = value.startOf('month').utc(false)
                  console.log(d.toISOString())
                  validateFieldChange('volumeDate',d, volumeRecord, setVolumeRecord, validateForm)
                }}
              />
              <DateField
                  format="MM/DD/YYYY" id='volumeDate'
                  label="Production Volume Date"
                  timezone='UTC'
                  value={dayjs(volumeRecord.volumeDate)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      disabled: true
                    },
                  }}
              />
            </Stack>
            </Grid>
          <Grid item sm={12} md={12} padding={1}>
          <Stack direction={'row'} spacing={2}>
              <Typography fontWeight='bold' width={225}>Production Code:</Typography>
              <Stack>

              { productionCodeOptions.length === 1 && 
              <TextField value={volumeRecord.productionCode}
                size='small'
                disabled
              />
              }

              { productionCodeOptions.length > 1 && 
              <>
                <Autocomplete options={productionCodeOptions} value={volumeRecord.productionCode}
                  size='small'
                  onChange={(event, value) => {
                    console.log(event)
                    console.log(value)
                    const option = value ?? event.target.value ?? undefined
                    validateFieldChange('productionCode',option ?? undefined, volumeRecord, setVolumeRecord, validateForm)
                    //validateFieldChange('productionCode',option.value ?? undefined, volumeRecord, setVolumeRecord, validateForm)
                  }}
                  sx={{ width: '100px'}}
                  renderInput={(params) => <TextField
                    {...params}
                    required={true}
                  />
                }
                />
                { !volumeRecord.productionCode && 
                  <FormHelperText error={true}>This field is required</FormHelperText>
                }
                </>
              }
              
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={12} md={12} padding={1}>
            <Stack direction={'row'} spacing={2}>
              <Typography fontWeight='bold' width={225}>Production Volume Amount:</Typography>
              <Stack>
              <TextField inputProps={{ maxLength: 9, datatype: 'number'}} value={volumeRecord.volumeAmount}
                size='small'
                sx={{ maxWidth: '200px'}}
                required
                onChange={(event) => {
                  const val = event.target.value
                  if (!isNaN(val)) {
                    validateFieldChange('volumeAmount',event.target.value, volumeRecord, setVolumeRecord, validateForm)
                  }
                }}
              />
              { !volumeRecord.volumeAmount && 
                <FormHelperText error={true}>This field is required</FormHelperText>
              }
              <Typography>Please enter volume amount as the number of containers, not the number of cases.</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        </Paper>
      }
    </Page>

  )
}