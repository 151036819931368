import { useContext, useEffect, useMemo, useState } from 'react'
import { calculateProgress } from '../context/AssessmentContext'
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import ChartJSBarChart from '../../charts/ChartJsBarChart'
import { RATINGS, RATING_LABELS } from '../../form/formUtils'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ChartJsPieChart from '../../charts/ChartJsPieChart'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import { ASMT_STATUS } from '../../utils/constants'
import { FileDownload } from '@mui/icons-material'
import { download, generateCsv, mkConfig } from 'export-to-csv'

export default function SummaryOfFindings(props={assessmentId: undefined, includedModels: undefined, assessmentItems: undefined, exportable: undefined}) {
  const [assessmentFindings, setAssessmentFindings] = useState([])
  const [noFindings, setNoFindings] = useState(false)

  const extractItemModel = (model) => {
    let children = []
    let maxPoints = 0
    
    if (['Category', 'Section'].includes(model.type)) {
      children = model.children.flatMap((child) => {
        return extractItemModel(child)
      })
    }
    if (model.type === 'Item') {
      maxPoints = parseFloat(model.props.maxPoints)
    }
    const itemModel = {
      id: model.props.id,
      title: model.props.title,
      maxPoints,
    }
    return [itemModel, ...children]
  }

  const initialize = () => {
    const _itemModels = props.includedModels.flatMap((m) => extractItemModel(m))
      
      const findings = props.assessmentItems.reduce((p,item) => {
        const included = item.findingRating && !['na','not_assessed','no_finding'].includes(item.findingRating)
        if (included) {
          const model = _itemModels.find(m => m.id === item.itemId)
          if (model) {
            const maxPoints = parseFloat(model.maxPoints)
            p.push({ ...item, title: model.title, maxPoints})
          }
        }
        return p
      },[])
      if (findings.length) {
        setAssessmentFindings(findings)
      } else {
        setNoFindings(true)
      }
  }

  useEffect(() => {
    initialize()
  }, [props.assessmentItems, props.includedModels])

  const columns = useMemo(() => [
    {
      accessorKey: 'itemId',
      header: 'Item',
      grow: false,
      size: 75
    },
    {
      accessorKey: 'title',
      header: 'Title',
      grow: true
    },
    {
      accessorKey: 'findingRating',
      // accessorFn: (row) => {return RATING_LABELS[row.findingRating]},
      header: 'Rating',
      filterVariant: 'multi-select',
      filterSelectOptions: Object.entries(RATING_LABELS).reduce((p, [v, l]) => {
        if (!['na','not_assessed','no_finding'].includes(v)) {
          p.push({ label: l, value: v })
        }
        return p
      }, []),
      grow: false,
      size: 250,
      Cell: ({cell}) => (<>
        <span style={{height: '20px', width: '20px', borderRadius: '50%', display: 'inline-block', border: 0 }} 
        className={`points ${cell.getValue().replace(/_/g,'-')}`}></span>
        <Typography fontSize='inherit !important' paddingLeft={1}>{ RATING_LABELS[cell.getValue()] }</Typography></>)
    },
    {
      accessorKey: 'isRepeat',
      header: 'Repeat',
      enableColumnFilter: false,
      grow: false,
      size: 70,
      Cell: ({cell}) => { return cell.getValue() === 0 ? 'No' : 'Yes' }
    },
    {
      accessorKey: 'earnedPoints',
      header: 'Points',
      enableColumnFilter: false,
      grow: false,
      size: 80,
      Cell: ({cell, row}) => { return `${parseFloat(row.original.earnedPoints).toFixed(1)} / ${row.original.maxPoints}` }
    }
  ], [assessmentFindings])

const table = useMaterialReactTable({
  data: assessmentFindings,
  columns: columns,
  layoutMode: 'grid',
  enableDensityToggle: false,
  enableHiding: false,
  enableColumnFilters: true,
  enablePagination: false,
  enableColumnDragging: false,
  enableSorting: false,
  enableColumnActions: false,
  displayColumnDefOptions: {'mrt-row-expand': { size: 30, grow: false } },
  initialState: {
    expanded: true,
    showColumnFilters: props.exportable
  },
  renderDetailPanel: ( {row} ) => {
    const set = Object.entries(row.original)
   return (
    <Stack spacing={1} paddingLeft={2}>
          {(row.original.areaFinding || '').length > 0 && <>
          <Typography variant='body2' style={{ fontWeight: 'bold'}}>Area Finding: Observed/Location</Typography>
          <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.areaFinding}</Typography>
          </>}
          <Typography variant='body2' style={{ fontWeight: 'bold'}}>Finding: Gaps/Observations</Typography>
          <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.findingObservations}</Typography>
          <Typography variant='body2' style={{ fontWeight: 'bold'}}>Objective Evidence</Typography>
          <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.objectiveEvidence}</Typography>
          <Typography variant='body2' style={{ fontWeight: 'bold'}}>QSE Objective Evidence</Typography>
          <Typography paddingLeft={2} style={{ whiteSpace: 'pre-wrap' }}>{row.original.objectiveEvidenceQse}</Typography>
        </Stack>
  )},
  muiTableBodyCellProps: {
    sx: {
      fontSize: '.82rem',
      fontWeight: 'bold',
      padding: '4px !important'
    }
  },
  muiTableHeadCellProps: {
    sx: {
      fontSize: '.9rem !important',
      verticalAlign: 'center'
    }
  },
  renderTopToolbar: true,
  renderBottomToolbarCustomActions: ({table}) => {
    if (props.exportable) return (
    <Stack direction={'row'}>
      <Button onClick={handleExportData} startIcon={<FileDownload />}>Export to File</Button>
    </Stack>
  )}
})

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
  filename: `${props.assessmentId}-Findings`,
  fileExtension: 'csv'
})
const handleExportData = () => {
  const rowData = table.getPrePaginationRowModel().rows.map((row) => {
    const r = row.original
    const n = {}
    n.AssessmentId = r.assessmentId
    n.ItemId = r.itemId
    n.Title = r.title
    n.Rating = RATING_LABELS[r.findingRating]
    n.Repeat = r.isRepeat ? 'Yes' : 'No'
    n.Area = r.areaFinding === null ? '' : r.areaFinding
    n.ObjectiveEvidence = r.objectiveEvidence === null ? '' : r.objectiveEvidence
    n.QSEObjectiveEvidence = r.objectiveEvidenceQse === null ? '' : r.objectiveEvidenceQse

    return n
  })
  const csv = generateCsv(csvConfig)(rowData);
  download(csvConfig)(csv);
}

return (<>
  {((assessmentFindings?.length ?? 0) > 0) && 
    <MaterialReactTable table={table} />
  }
  { noFindings &&
    <Typography variant='body2' paddingTop={2}>No Findings Observed</Typography> 
  }
</>)}